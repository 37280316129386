/* eslint-disable import/first */

import config from './js/config';
import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom'

import { ToastContainer, Slide } from 'react-toastify';

// Library styles for all of the app
import 'react-toastify/dist/ReactToastify.css';
import 'react-select/dist/react-select.css';
import 'react-phone-number-input/rrui.css'
import 'react-phone-number-input/style.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import * as queryString from 'query-string';
import Cookies from 'universal-cookie';
import moment from 'moment';
import {StripeProvider} from 'react-stripe-elements';

import "./js/lang/i18n";


// 'Main' Containers
import ContainerLoading from './js/components/containers/ContainerLoading';
import TopBar from './js/components/TopBar/TopBar';
import Footer from './js/components/Footer/Footer';
import Login from './js/components/Login/Login';
import PasswordResetContainer from './js/components/containers/PasswordResetContainer';
import Logout from './js/components/Login/Logout';
import PagePrivacy from './js/components/containers/PagePrivacy';
import PageTerms from './js/components/containers/PageTerms';
import Maintenance from './js/components/containers/Maintenance';

import UISideNav from './js/components/UISideNav/UISideNav';



import Analytics from './js/components/Analytics/Analytics';
import OneTimeToken from './js/components/Login/OneTimeToken';
import Register from './js/components/Registration/Registration';
import SignupContainer from './js/components/containers/SignupContainer';
import SignupBContainer from './js/components/containers/SignupBContainer';
import NewSignupContainer from './js/components/containers/NewSignupContainer';
import SignupDemoContainer from './js/components/containers/SignupDemoContainer';
import AccountActivation from './js/components/AccountActivation/AccountActivation';

// Containers to load as needed

const DashboardHome = lazy(() => import('./js/components/DashboardHome/DashboardHome'))
const Contacts = lazy(() => import('./js/components/Contacts/Contacts'))
const Organisations = lazy(() => import('./js/components/containers/OrganisationsContainer'))
const OrganisationView = lazy(() => import('./js/components/Organisations/OrganisationView'))
const EmailComposer = lazy(() => import('./js/components/EmailComposer/EmailComposer'))
const BillingContainer = lazy(() => import('./js/components/containers/BillingContainer'))
const ContactViewContainer = lazy(() => import('./js/components/ContactViewContainer/ContactViewContainer'))
const ContactFinanceRedirect = lazy(() => import('./js/components/Contacts/ContactFinanceRedirect'))
const PrivateRoute = lazy(() => import('./js/components/PrivateRoute/PrivateRoute'))
const ImportCSVContainer = lazy(() => import('./js/components/containers/ImportCSVContainer'))
const ImportSheet = lazy(() => import('./js/components/Import/ImportSheet'))
const ImportXero = lazy(() => import('./js/components/Import/ImportXero'))
const ImportQuickbooks = lazy(() => import('./js/components/Import/ImportQuickbooks'))
const ImportCSV = lazy(() => import('./js/components/Import/ImportCSV'))
const AppointmentsContainer = lazy(() => import('./js/components/containers/AppointmentsContainer'))
const OpportunitiesContainer = lazy(() => import('./js/components/containers/OpportunitiesContainer'))
const ProjectsContainer = lazy(() => import('./js/components/containers/ProjectsContainer'))
const ProjectViewContainer = lazy(() => import('./js/components/containers/ProjectViewContainer'))
const SubscriptionsContainer = lazy(() => import('./js/components/containers/SubscriptionsContainer'))
const TelephoneContainer = lazy(() => import('./js/components/containers/TelephoneContainer'))
const ProfileSettingsContainer = lazy(() => import('./js/components/containers/ProfileSettingsContainer'))
const SettingsContainer = lazy(() => import('./js/components/containers/SettingsContainer'))
const SettingsIntegrationContainer = lazy(() => import('./js/components/containers/SettingsIntegrationContainer'))
const CallContainer = lazy(() => import('./js/components/containers/CallContainer'))
// const EmailListContainer = lazy(() => import('./js/components/containers/EmailListContainer'))
const TasksContainer = lazy(() => import('./js/components/Tasks/TasksContainer'))
const ConfirmationContainer = lazy(() => import('./js/components/containers/ConfirmationContainer'))
const CampaignsContainer = lazy(() => import('./js/components/containers/CampaignsContainer'))
const CampaignView = lazy(() => import('./js/components/Campaigns/CampaignView'))
const ReportingContainer = lazy(() => import('./js/components/containers/ReportingContainer'))
const TestsContainer = lazy(() => import('./js/components/Tests/Tests'))
const FormBuilderContainer = lazy(() => import('./js/components/containers/FormBuilderContainer'))
const FormBuilderEditContainer = lazy(() => import('./js/components/containers/FormBuilderEditContainer'))
const IntegrationsContainer = lazy(() => import('./js/components/Integrations/Integrations'))
const GroupsContainer = lazy(() => import('./js/components/containers/GroupsContainer'))
const GroupViewContainer = lazy(() => import('./js/components/containers/GroupViewContainer'))
const AutomationsContainer = lazy(() => import('./js/components/containers/AutomationsContainer'))


// Enterprise Example
// import TenderOppsContainer from './js/components/Enterprise/TTP/TenderOpps/TenderOppsContainer';

import './App.css';



// import {TeamPreferenceContext, teamPreferences} from './js/data/TeamPreferenceContext';


const NoMatch = ({ location }) => (
	<div className="container page-dashboard pt-5">
		<strong>Sorry, but that page wasn't found: </strong> 
		<code>{location.pathname}</code>	
	</div>
);


class App extends React.Component {
	state = {
		notificationsCount: 3,
	}

	componentWillMount() {
	}


	componentWillUnmount() {
	}


	componentDidMount() {
		this.checkCodes();
	}


	/*
	 * Used to check for & set beta and referral codes 
	 */
	checkCodes = () => {
		const query = queryString.parse(window.location.search);
		const cookies = new Cookies();
		if (query.beta !== undefined) cookies.set('beta', query.beta, {
			path: '/',
			expires: new Date(moment().add(30, 'd').format()),
			// domain: '.reldesk.com',
		});
		
		if (query.ref !== undefined) {
			cookies.set('ref', query.ref, {
				path: '/',
				expires: new Date(moment().add(60, 'd').format()),
				// domain: '.reldesk.com',
			});
			window.analytics.track('Referred User Arrival', { referralCode: query.ref });
		}

	}


	render() {

		if (config.maintenance_mode) return (<Maintenance/>);

		return (
			<div className="App">
			<div>
				<ToastContainer transition={Slide} progressStyle={{ height: "3px", background: "linear-gradient(90deg, rgba(62,28,194,1) 0%, rgba(138,40,142,1) 35%, rgba(234,103,127,1) 100%)" }}/>

				<UISideNav open={true} visible={false}>
					<h4 style={{paddingTop: 10}}>Getting Started</h4>
					<p>First add some contacts to your account in the Contacts menu.</p>
				</UISideNav>

				<TopBar notificationsCount={this.state.notificationsCount} />
				<Suspense fallback={<ContainerLoading/>}>
					<Switch>
						<Route path='/login' component={Login} />
						<Route path='/token/:token' component={OneTimeToken} />
						<Route path='/signup' component={SignupContainer} />
						<Route path='/signupb' component={SignupBContainer} />
						
						{/* The New Signup Process (billing plans) */}
						<Route path='/newsignup/:plan_id' component={NewSignupContainer} />
						<Route path='/newsignup' component={NewSignupContainer} />
						
						{/* Sales Demo Request */}
						<Route path='/signupdemo/:source' component={SignupDemoContainer} />
						<Route path='/signupdemo' component={SignupDemoContainer} />

						<Route path='/register/:package_id/:package_period' component={Register} />
						<Route path='/register' component={Register} />
						<Route path='/password/reset/:token' component={PasswordResetContainer} />
						<Route path='/password/reset' component={PasswordResetContainer} />
						<Route path='/logout' component={Logout} />
						<Route exact path='/account/activation' component={AccountActivation} />
						<Route exact path='/account/activation/:id' component={AccountActivation} />
						<Route exact path='/page/terms' component={PageTerms} />
						<Route exact path='/page/privacy' component={PagePrivacy} />
						
						<PrivateRoute exact path='/dashboard' component={DashboardHome} />
						<PrivateRoute exact path='/contacts/importcsv' component={ImportCSVContainer} />
						<PrivateRoute exact path='/contacts/import/sheet/:id' component={ImportSheet} />
						<PrivateRoute exact path='/contacts/import/xero' component={ImportXero} />
						<PrivateRoute exact path='/contacts/import/qbooks' component={ImportQuickbooks} />
						<PrivateRoute exact path='/contacts/import/csv' component={ImportCSV} />
						<PrivateRoute exact path='/contacts' component={props => <Contacts {...props} />}/>
						<PrivateRoute exact path='/organisations' component={props => <Organisations {...props} />}/>
						<PrivateRoute exact path='/organisations/:id' component={OrganisationView}/>
						<PrivateRoute exact path='/opportunities' component={OpportunitiesContainer} />
						<PrivateRoute exact path='/projects' component={ProjectsContainer} />
						<PrivateRoute exact path='/project/:id' component={ProjectViewContainer} />
						<PrivateRoute exact path='/subscriptions' component={SubscriptionsContainer} />
						<PrivateRoute exact path='/telephone' component={TelephoneContainer} />
						<PrivateRoute exact path='/campaigns/:campaignid' component={CampaignView} />
						<PrivateRoute exact path='/campaigns' component={CampaignsContainer} />
						<PrivateRoute exact path='/groups/:id' component={GroupViewContainer} />
						<PrivateRoute exact path='/groups' component={GroupsContainer} />
						<PrivateRoute exact path='/automations' component={AutomationsContainer} />

	 					<PrivateRoute exact path='/call/:contactid/:number' component={CallContainer} />
	 					<PrivateRoute exact path='/call' component={CallContainer} />
	 					<PrivateRoute exact path='/billing' component={BillingContainer} />
						<PrivateRoute exact path='/contacts/finance/xero/:id' component={ContactFinanceRedirect} />
						<PrivateRoute exact path='/contacts/:id' component={ContactViewContainer} />
						<PrivateRoute exact path='/appointments' component={AppointmentsContainer} />
						<PrivateRoute exact path='/tasks' component={TasksContainer} />
						<PrivateRoute exact path='/reporting' component={ReportingContainer} />
						<PrivateRoute exact path='/reporting/:report' component={ReportingContainer} />
						<PrivateRoute exact path='/forms/:form_id' component={FormBuilderEditContainer} />
						<PrivateRoute exact path='/forms' component={FormBuilderContainer} />

						{/* <PrivateRoute exact path='/email' component={EmailListContainer} /> 
						<PrivateRoute exact path='/email/compose' component={EmailComposer} />
						<PrivateRoute exact path='/email/contact/:id' component={EmailComposer} />*/}
						<PrivateRoute exact path='/profile/settings' component={ProfileSettingsContainer} />
						<PrivateRoute exact path='/settings/account' component={SettingsContainer} />
						<PrivateRoute exact path='/settings/account/:id' component={SettingsContainer} />
						<PrivateRoute exact path='/settings/integration/:id' component={SettingsIntegrationContainer} />
						<PrivateRoute exact path='/confirmation/:type' component={ConfirmationContainer} />

						<PrivateRoute exact path='/tests/:id' component={TestsContainer} />
						
						<PrivateRoute exact path='/integrations/:id' component={IntegrationsContainer} />

						{/* <PrivateRoute exact path='/tenderopps' component={TenderOppsContainer} /> */}

						<Route exact path='/' component={Login} />
						<Route component={NoMatch} />
					</Switch>
				</Suspense>
				<Analytics/>
				<Footer/>
				</div>
			</div>
		);
	}
}


export default App;
