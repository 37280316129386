

module.exports = {
	api: {
		// server: 'http://localhost:3001',
		// server_base: 'localhost:3001',
		server: 'https://apilive.reldesk.com',
		server_base: 'apilive.reldesk.com',
		// server: 'https://reldesk-api.cloud.distortionhosting.co.uk',
		// server_base: 'reldesk-api.cloud.distortionhosting.co.uk',
		// dataserver: "https://reldesk-data-server.now.sh",
		dataserver: "https://ds.reldesk.com",
		// dataserver: "http://localhost:3005",
		// server: 'https://reldesk-api-frosn.ondigitalocean.app',
		// server_base: 'reldesk-api-frosn.ondigitalocean.app',
	},

	// domain: 'http://localhost:3000',
	domain: 'https://app.reldesk.com',
	maintenance_mode: false,

	services: {
		google: {
			client_id: '83332490613-2sa9t7e8vkb6e57g4u7kfp9rmidfhmou.apps.googleusercontent.com',
			developer_key: 'AIzaSyDqxgj-Tz69vVSU4jLq8zlqR6eaKDM6TOI',
			scope: ['https://www.googleapis.com/auth/drive.readonly']
		},
		stripe: {
			// key: 'pk_test_HVYesEQCDSDrAaJyTDHziTc2'
			key: 'pk_live_qFVipgxRyrUJkoa6COC6yAjN'
		},
	},

};
